import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import localforage from 'localforage';
import './PinStart.css';

const maxTries = 3;

const PageTwo = () => {
    const [pin, setPin] = useState('');
    const [tries, setTries] = useState(0);
    const [correctPin, setCorrectPin] = useState('');
    const [adminPin, setAdminPin] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Load PINs from localforage
        const loadPins = async () => {
            const savedGamePin = await localforage.getItem('gamePin');
            const savedAdminPin = await localforage.getItem('adminPin');
            setCorrectPin(savedGamePin || '1397'); // Default to 1397 if not set
            setAdminPin(savedAdminPin || '0906'); // Default to 0906 if not set
        };

        loadPins();

        // Fade-in animation
        gsap.fromTo(".page-content, .page-title, .submit-button", 
            { opacity: 0 }, 
            { opacity: 1, duration: 0.5 }
        );
    }, []);

    const handleButtonClick = (value) => {
        if (pin.length < 6) {
            setPin(pin + value);
        }
    };

    const handleSubmit = () => {
        if (pin === correctPin || pin === adminPin) {
            // Fade out animation and then redirect
            gsap.to(".page-content, .page-title, .submit-button", {
                opacity: 0,
                duration: 0.5,
                onComplete: () => {
                    if (pin === correctPin) {
                        navigate('/Terms');
                    } else if (pin === adminPin) {
                        navigate('/admin');
                    }
                }
            });
        } else {
            setPin('');
            setTries(tries + 1);
            if (tries + 1 >= maxTries) {
                navigate('/');
            }
        }
    };

    const renderDots = () => {
        return [0, 1, 2, 3, 4, 5].map((index) => (
            <div key={index} className="pin-slot">
                <span className="underscore"></span>
                {pin.length > index && <span className="dot"></span>}
            </div>
        ));
    };

    return (
        <div className="page-container">
            <h1 className="page-title">Enter PIN</h1>
            <div className='page-content'>
                <div className="pin-display">{renderDots()}</div>
                <div className="numpad">
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((num) => (
                        <button key={num} onClick={() => handleButtonClick(num)}>{num}</button>
                    ))}
                    <button className="zero-button" onClick={() => handleButtonClick('0')}>0</button>
                </div>
            </div>
            <button className="submit-button page-button" onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default PageTwo;
