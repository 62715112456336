import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import unselectedStar from '../assets/images/Rate_Default.png'; // Path to your unselected star image
import selectedStar from '../assets/images/Rate_Selected.png'; // Path to your selected star image
import './Success.css'; // Optional, for specific page styles

const Success = () => {
    const [rating, setRating] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { contactInfo } = location.state || {};

    useEffect(() => {
        // Fade in animation on page load
        gsap.fromTo(".page-container", { opacity: 0 }, { opacity: 1, duration: 0.5 });
    }, []);

    const handleRating = (selectedRating) => {
        setRating(selectedRating);
        // Scale the star icon on click
        const starIcon = document.getElementById(`star-${selectedRating}`);
        gsap.fromTo(starIcon, { scale: 1 }, { scale: 1.5, duration: 0.2, yoyo: true, repeat: 1 });
    };

    const handleRatingSubmit = () => {
        // Fade out animation and then navigate
        gsap.to(".page-container", {
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
                navigate('/brands', { state: { rating, contactInfo } });
            }
        });
    };

    return ( <
        div className = "success page-container" >
        <
        div className = 'page-header' >
        <
        h1 className = "page-title-small" > Rate Your Experience < /h1> < /
        div > <
        div className = 'page-content' >
        <
        div className = "rating-stars" > {
            [1, 2, 3, 4, 5].map((star) => ( <
                img key = { star }
                id = { `star-${star}` }
                src = { star <= rating ? selectedStar : unselectedStar }
                alt = { `${star} star` }
                onClick = {
                    () => handleRating(star)
                }
                className = "rating-star" /
                >
            ))
        } <
        /div> < /
        div > <
        div className = 'page-footer' >
        <
        button className = "page-button"
        onClick = { handleRatingSubmit } > Submit < /button> < /
        div > <
        /div>
    );
};

export default Success;