import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { gsap } from 'gsap';
import { fetchTerms } from '../services/database';
import he from 'he'; // For decoding HTML entities
import './Terms.css'; // Optional, for specific page styles
import float1 from '../assets/images/Icon_Chat_Light.png';
import float2 from '../assets/images/Icon_Message_Light.png';
import float3 from '../assets/images/Icon_Like_Dark.png';
import float4 from '../assets/images/Icon_Message_Dark.png';

const PageThree = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [termsVariant, setTermsVariant] = useState('variant1');
    const [content, setContent] = useState(''); // State to store term content
    const navigate = useNavigate();

    useEffect(() => {
        const loadTermsVariant = async () => {
            // Retrieve saved terms variant from local storage
            const savedTermsVariant = await localforage.getItem('termsVariant');
            console.log('Saved Terms Variant:', savedTermsVariant);

            if (savedTermsVariant) {
                setTermsVariant(savedTermsVariant);
            }

            // Fetch all terms from the database
            const terms = await fetchTerms();
            console.log('Fetched Terms:', terms);

            const foundTerm = terms.find(term => Number(term.id) === Number(savedTermsVariant));

            if (foundTerm) {
                console.log('Found term content:', foundTerm.content);

                // Decode HTML entities if necessary
                const decodedContent = he.decode(foundTerm.content);
                setContent(decodedContent); // Set decoded content to state
            } else {
                console.log('Term not found.');
                setContent('Terms and conditions not found.');
            }
        };

        loadTermsVariant();

        // Animations setup
        gsap.fromTo(".page-content, .page-title, .page-footer", { opacity: 0 }, { opacity: 1, duration: 0.5 });

        // Float animation setup
        const timeline = gsap.timeline();
        document.querySelectorAll(".floating-image").forEach((element) => {
            timeline.to(element, {
                x: `+=${Math.random() * 40 - 20}px`,
                y: `+=${Math.random() * 40 - 20}px`,
                repeat: -1,
                yoyo: true,
                duration: 4,
                ease: "power1.inOut",
            });
        });
    }, []);

    const handleCheckboxChange = () => setIsChecked(!isChecked);

    const handleSubmit = () => {
        gsap.to(".page-content, .page-title, .page-footer", {
            opacity: 0,
            duration: 0.5,
            onComplete: () => navigate(isChecked ? '/Slot' : '/'),
        });
    };

    return (
        <div className="page-container">
            <div className="page-header">
                <h1 className="page-title-small">Terms & Conditions</h1>
            </div>
            <div className="page-content ">
                <div className="terms-content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="page-footer">
             
                <div className="checkbox-container" >
                    <input type="checkbox"
                        id="terms"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    /> 
                    <label htmlFor="terms" > I agree to the terms and agreements </label> </div> </div> 
                    <div className='page-footer' >
                            <button className="page-button"
                                onClick={handleSubmit} >
                                Submit </button> 
                                </div> 
                                <img src={float1}
                                    alt="chat-ligth-1"
                                    className="floating-image chat-ligth-1" />
                                <img src={float2}
                                    alt="message-ligth-1"
                                    className="floating-image message-ligth-1" />
                                <img src={float3}
                                    alt="like-dark-1"
                                    className="floating-image like-dark-1" />
                                <img src={float3}
                                    alt="like-dark-2"
                                    className="floating-image like-dark-2" />
                                <img src={float4}
                                    alt="message-dark-1"
                                    className="floating-image message-dark-1" />
                                </div>
                
                );
};

                export default PageThree;
