import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import './Winner.css';

const ContactInfoForm = () => {
  const location = useLocation();
  const winnerId = location.state?.winnerId || 9001; // Set winnerId to 9001 if not provided

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [option, setOption] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fade in animation on page load
    gsap.fromTo(".page-container", 
      { opacity: 0 }, 
      { opacity: 1, duration: 0.5 }
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const contactInfo = {
      first_name,
      last_name,
      email,
      option,
      winner_id: winnerId, // Include winnerId in contact info
    };
    // Fade out animation and then navigate
    gsap.to(".page-container", {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        navigate('/pinEnd', { state: { contactInfo } });
      }
    });
  };

  return (
    <div className="page-container">
      <h3 className="page-title">Enter your</h3>
      <h1 className="page-title">Details:</h1>
      
      <form className="" onSubmit={handleSubmit}>
        <div className='page-content'>
          <div className='contact-info-form'>
            <div className="form-group">
              <label>First Name</label>
              <input type="text" value={first_name} onChange={(e) => setFirstName(e.target.value)} required />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input type="text" value={last_name} onChange={(e) => setLastName(e.target.value)} required />
            </div>
            <div className="form-group-full">
              <label>Email address</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group-full">
              <div className="radio-group">
                <label>
                  <input type="radio" value="option1" checked={option === 'option1'} onChange={(e) => setOption(e.target.value)} />
                  <span></span> $500 - Hotel voucher
                </label>
                <label>
                  <input type="radio" value="option2" checked={option === 'option2'} onChange={(e) => setOption(e.target.value)} />
                  <span></span> $500 - Flight voucher
                </label>
                <label>
                  <input type="radio" value="option3" checked={option === 'option3'} onChange={(e) => setOption(e.target.value)} />
                  <span></span> $250 - Each voucher
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="center-button">
          <button type="submit" className="page-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ContactInfoForm;
