import React from 'react';

const LoserPage = () => {
    return (
        <div className="page-container">
          <h1 className="page-title">You are a loser!</h1>
        </div>
      );
};

export default LoserPage;
