import React, { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import "./SlotMachine.css";
import img1 from "../assets/images/Roulette_Adventurer.png";
import img2 from "../assets/images/Roulette_Cultured.png";
import img3 from "../assets/images/Roulette_Hedonist.png";
import img4 from "../assets/images/Roulette_Indulgent.png";
import img5 from "../assets/images/Roulette_Relaxer.png";
import refreshImg from "../assets/images/RefreshArrows.png";
import checkmarkImg from "../assets/images/Check.png"; // Path to your checkmark image
import float1 from "../assets/images/Icon_Chat_Light.png";
import float2 from "../assets/images/Icon_Message_Light.png";
import float3 from "../assets/images/Icon_Like_Dark.png";
import float4 from "../assets/images/Icon_Message_Dark.png";
import { fetchClaimableWinners } from "../services/database";
import localforage from "localforage";

const images = [img1, img2, img3, img4, img5];

const SlotMachine = () => {
        const reel1Ref = useRef(null);
        const reel2Ref = useRef(null);
        const reel3Ref = useRef(null);
        const [result, setResult] = useState("");
        const [spinType, setSpinType] = useState(null);
        const [currentReel, setCurrentReel] = useState(0);
        const [spinning, setSpinning] = useState(false);
        const [spinSpeed, setSpinSpeed] = useState(10); // Default spin speed
        const [reelRotations, setReelRotations] = useState(25); // Default reel rotations
        const [swipeCueText, setSwipeCueText] = useState("Drag down to spin");
        const navigate = useNavigate();
        const targetIndexes = useRef([0, 0, 0]); // Ref to store target indexes
        const [headerText, setHeaderText] = useState({
            small: "Match 3 posts",
            large: "To win!",
        });
        const [showSwipeCue, setShowSwipeCue] = useState(true);
        const [winnerId, setWinnerId] = useState(null); // State to store winner ID
        const [debugMode, setDebugMode] = useState(false);
        const [alwaysWin, setAlwaysWin] = useState(false);
        const random = (min, max) => Math.random() * (max - min) + min;
        useEffect(() => {
            const timeline = gsap.timeline();
            // Reveal slot machine
            timeline.to(".slot-machine", { opacity: 1, duration: 1 }, "-=1.5");

            // Continue smaller floating animations with random motion
            document.querySelectorAll(".floating-image").forEach((element) => {
                timeline.to(
                    element, {
                        x: `+=${random(-30, 30)}px`,
                        y: `+=${random(-30, 30)}px`,
                        repeat: -1,
                        yoyo: true,
                        duration: random(3, 5),
                        ease: "power1.inOut",
                    },
                    "-=1",
                );
            });


            const loadConfig = async() => {
                const savedSpinSpeed = await localforage.getItem("spinSpeed");
                const savedReelRotations = await localforage.getItem("reelRotations");
                const savedDebugMode = await localforage.getItem("debugMode");
                const savedAlwaysWin = await localforage.getItem("alwaysWin");

                if (savedSpinSpeed) setSpinSpeed(savedSpinSpeed);
                if (savedReelRotations) setReelRotations(savedReelRotations);
                if (savedDebugMode) setDebugMode(savedDebugMode);
                if (savedAlwaysWin) setAlwaysWin(savedAlwaysWin);
            };

            loadConfig();
        }, []);

        const spinReel = (reelInner, targetIndex, reelIndex) => {
            console.log(
                `Spinning reel ${reelIndex + 1} to target index: ${targetIndex}`,
            );
            const totalImages = images.length;
            const symbolHeight = reelInner.querySelector(".symbol").clientHeight; // Get the height of a single symbol dynamically
            const yPos = -((reelRotations * totalImages + targetIndex) * symbolHeight); // Total distance to spin
            const reelSticker = reelInner.parentNode.querySelector(".reel-sticker");

            const timeline = gsap.timeline();

            // Animate the swipe-down and reelSticker simultaneously
            timeline.to(
                ".swipe-down", {
                    scale: 1.2,
                    y: 10,
                    duration: 0.2,
                    ease: "power1.out",
                },
                0,
            ); // The `0` ensures this animation starts at the beginning of the timeline

            timeline.to(
                reelSticker, {
                    y: -yPos,
                    duration: spinSpeed, // Use dynamic spin speed
                    ease: "power3.inOut",
                    onComplete: () => {
                        // reelSticker.style.display = 'none'; // Hide sticker after animation
                    },
                },
                0,
            ); // The `0` ensures this animation starts at the beginning of the timeline

            setCurrentReel((prev) => prev + 1);
            // Animate the swipe-down and reelSticker simultaneously
            timeline.to(
                ".swipe-down", {
                    scale: 1,
                    y: -10,
                    duration: 0.2,
                    ease: "power1.out",
                },
                0,
            ); // The `0` ensures this animation starts at the beginning of the timeline
            if (reelIndex < 2) {
                setSwipeCueText(`Drag down to spin reel ${reelIndex + 2}`);
            } else {
                setSwipeCueText(`Good luck!`);
                const timeline = gsap.timeline();
                // Animate floating images into the screen with opacity
                timeline.fromTo(
                    ".chat-ligth-1", { opacity: 0 }, { opacity: 1, duration: 2, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".message-ligth-1", { opacity: 0 }, { opacity: 1, duration: 3, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".like-dark-1", { opacity: 0 }, { opacity: 1, duration: 2, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".like-dark-2", { opacity: 0 }, { opacity: 1, duration: 2.2, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".message-dark-1", { opacity: 0 }, { opacity: 1, duration: 2.5, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".message-dark-2", { opacity: 0 }, { opacity: 1, duration: 2.5, ease: "power1.in" },
                    0,
                );
                timeline.fromTo(
                    ".message-ligth-2", { opacity: 0 }, { opacity: 1, duration: 2.5, ease: "power1.in" },
                    0,
                );
            }

            gsap.to(reelInner, {
                y: -yPos,
                duration: spinSpeed, // Use dynamic spin speed
                ease: "power3.inOut",
                modifiers: {
                    y: (y) => `${parseInt(y) % (totalImages * symbolHeight)}px`,
                },
                onComplete: () => {
                    console.log(
                        `Completed spinning reel ${reelIndex + 1} to target index: ${targetIndex}`,
                    );
                    gsap.set(reelInner, { y: targetIndex * symbolHeight });

                    // Set border of completed reel to yellow
                    gsap.to(reelInner.parentNode, {
                        border: "4px solid #fce501",
                        duration: 0.5,
                    });

                    setSpinning(false); // Allow user to spin the next reel

                    if (reelIndex === 2) {
                        // Display the result after the last reel stops
                        if (spinType === "win") {
                            setResult("You are a lucky winner!");
                            setHeaderText({
                                large: "winner!",
                                small: "You are a lucky",
                            });
                        } else {
                            setResult("Better luck next time");
                            setHeaderText({
                                large: "NEXT TIME!",
                                small: "Oh no... Better luck",
                            });
                        }
                        setShowSwipeCue(false); // Hide the swipe cue after the spin
                    }
                },
            });

            return targetIndex;
        };

        const handleSpinType = (type) => {
            console.log(`Selected spin type: ${type}`);
            setSpinType(type);
            setResult("");
            setCurrentReel(0);
            setSpinning(false); // Unlock buttons to allow manual spinning

            const targetIndex = Math.floor(Math.random() * images.length);
            targetIndexes.current = [targetIndex, targetIndex, targetIndex];

            if (type === "lose") {
                targetIndexes.current[2] = (targetIndexes.current[1] + 1) % images.length; // Ensure the third reel is different
            }
        };

        const checkForWinner = async() => {
            const winners = await fetchClaimableWinners();
            if (winners.length > 0) {
                setWinnerId(winners[0].id); // Set the winner ID
                handleSpinType("win");
            } else if (debugMode && alwaysWin) {
                setWinnerId(9001); // Debug 9001
                handleSpinType("win");
            } else {
                handleSpinType("lose");
            }
        };

        const swipeHandlers = useSwipeable({
            onSwipedDown: () => {
                if (!spinning && currentReel < 3) {
                    gsap.to(".swipe-down", {
                        scale: 1.2,
                        y: 10,
                        duration: 0.2,
                        ease: "power1.out",
                        onComplete: spinReelFromUserAction,
                    });
                }
            },

            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        });

        const spinReelFromUserAction = () => {
            if (currentReel === 0) {
                checkForWinner().then(() => {
                    spinReel(
                        reel1Ref.current.querySelector(".reel-inner"),
                        targetIndexes.current[0],
                        0,
                    );
                });
            } else if (currentReel === 1) {
                spinReel(
                    reel2Ref.current.querySelector(".reel-inner"),
                    targetIndexes.current[1],
                    1,
                );
            } else if (currentReel === 2) {
                spinReel(
                    reel3Ref.current.querySelector(".reel-inner"),
                    targetIndexes.current[2],
                    2,
                );
            }
        };

        const handleRedirect = (path) => {
            console.log(`Redirecting to: ${path}`);
            navigate(path, { state: { winnerId } });
        };

        return ( <
                div className = "page-container slot" {...swipeHandlers } >
                <
                div className = "page-header" >
                <
                h3 className = "page-title-small" > { headerText.small } < /h3>{" "} <
                h1 className = "page-title-large" > { headerText.large } < /h1>{" "} < /
                div > { " " } <
                div className = "page-content" >
                <
                div className = "slot-machine"
                style = {
                    { opacity: 0 }
                } >
                <
                div className = "reels" >
                <
                div className = "reel"
                ref = { reel1Ref } >
                <
                div className = "reel-sticker" > { " " } <
                h1 > 1 < /h1> < /
                div > <
                div className = "reel-inner" > { " " } {
                    images
                        .concat(images)
                        .concat(images)
                        .map((img, index) => ( <
                            div className = "symbol"
                            key = { index }
                            id = { `symbol-${(index % images.length) + 1}` } >
                            <
                            img src = { img }
                            alt = { `symbol ${(index % images.length) + 1}` }
                            />{" "} < /
                            div >
                        ))
                } { " " } <
                /div>{" "} {
                currentReel > 0 && ( <
                    img src = { checkmarkImg }
                    alt = "Checkmark"
                    className = "checkmark" / >
                )
            } { " " } <
            /div>{" "} <
        div className = "reel"
        ref = { reel2Ref } >
            <
            div className = "reel-sticker" > { " " } <
            h1 > 2 < /h1> < /
        div > <
            div className = "reel-inner" > { " " } {
                images
                    .concat(images)
                    .concat(images)
                    .map((img, index) => ( <
                        div className = "symbol"
                        key = { index }
                        id = { `symbol-${(index % images.length) + 1}` } >
                        <
                        img src = { img }
                        alt = { `symbol ${(index % images.length) + 1}` }
                        />{" "} < /
                        div >
                    ))
            } { " " } <
            /div>{" "} {
        currentReel > 1 && ( <
            img src = { checkmarkImg }
            alt = "Checkmark"
            className = "checkmark" / >
        )
    } { " " } <
    /div>{" "} <
div className = "reel"
ref = { reel3Ref } >
    <
    div className = "reel-sticker" > { " " } <
    h1 > 3 < /h1> < /
div > <
    div className = "reel-inner" > { " " } {
        images
            .concat(images)
            .concat(images)
            .map((img, index) => ( <
                div className = "symbol"
                key = { index }
                id = { `symbol-${(index % images.length) + 1}` } >
                <
                img src = { img }
                alt = { `symbol ${(index % images.length) + 1}` }
                />{" "} < /
                div >
            ))
    } { " " } <
    /div>{" "} {
currentReel > 2 && ( <
    img src = { checkmarkImg }
    alt = "Checkmark"
    className = "checkmark" / >
)
} { " " } <
/div>{" "} < /
div > { " " } <
    /div>{" "} < /
div > { " " } <
    div className = "page-footer" > { " " } {
        showSwipeCue && !spinning && ( <
            div className = "swipe-cue" > { " " } { swipeCueText } { " " } <
            div > { " " } <
            img src = { refreshImg }
            alt = "swipe-down"
            className = "swipe-down" /
            >
            { " " } <
            /div>{" "} < /
            div >
        )
    } { " " } {
        result && ( <
            div className = "result-buttons" > { " " } {
                result === "You are a lucky winner!" ? ( <
                    p onClick = {
                        () => handleRedirect("/winner")
                    } >
                    <
                    span > A member of staff will < /span> <
                    br / >
                    <
                    span > assist you with the next steps < /span>{" "} < /
                    p >
                ) : ( <
                    p onClick = {
                        () => handleRedirect("/success")
                    } > Thanks
                    for playing < /p>
                )
            } { " " } <
            /div>
        )
    } { " " } <
    /div>{" "} <
img src = { float1 }
alt = "chat-ligth-1"
className = "floating-image chat-ligth-1" /
    >
    <
    img src = { float2 }
alt = "message-ligth-1"
className = "floating-image message-ligth-1" /
    >
    <
    img src = { float2 }
alt = "message-ligth-2"
className = "floating-image message-ligth-2" /
    >
    <
    img src = { float3 }
alt = "like-dark-1"
className = "floating-image like-dark-1" /
    >
    <
    img src = { float3 }
alt = "like-dark-2"
className = "floating-image like-dark-2" /
    >
    <
    img src = { float4 }
alt = "message-dark-1"
className = "floating-image message-dark-1" /
    >
    <
    img src = { float4 }
alt = "message-dark-2"
className = "floating-image message-dark-2" /
    >
    <
    /div>
);
};

export default SlotMachine;