// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { initializeDatabase } from './services/database';
import { syncData } from './services/sync';
import localforage from 'localforage';
import Admin from './components/Admin';
import Start from './components/Start';
import PinStart from './components/PinStart';
import Terms from './components/Terms';
import Slot from './components/Slot';
import WinnerPage from './components/Winner';
import LoserPage from './components/Loser';
import PinEntry from './components/PinContact';
import Success from './components/Success';
import Brands from './components/Brands';
import Summary from './components/Summary';

const App = () => {
  const [debugMode, setDebugMode] = useState(false);

  useEffect(() => {
    const onDeviceReady = async () => {
      await initializeDatabase();
      await syncData();
      
      // Load the debug mode setting from localforage
      const savedDebugMode = await localforage.getItem('debugMode');
      console.log('Loaded debug mode:', savedDebugMode);
      if (savedDebugMode !== null) {
        setDebugMode(savedDebugMode);
      } else {
        console.log('Debug mode not set, defaulting to false.');
      }
    };

    document.addEventListener('deviceready', onDeviceReady, false);

    return () => {
      document.removeEventListener('deviceready', onDeviceReady);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {debugMode && (
          <nav>
            <ul className="navbar">
              <li><Link to="/admin">Admin</Link></li>
              <li><Link to="/">Start</Link></li>
              <li><Link to="/PinStart">Pin</Link></li>
              <li><Link to="/Terms">Terms</Link></li>
              <li><Link to="/Slot">Slot</Link></li>
              <li><Link to="/winner">Winner</Link></li>
              <li><Link to="/loser">Loser</Link></li>
            </ul>
          </nav>
        )}
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path="/" element={<Start />} />
          <Route path="/PinStart" element={<PinStart />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Slot" element={<Slot />} />
          <Route path="/winner" element={<WinnerPage />} />
          <Route path="/loser" element={<LoserPage />} />
          <Route path="/pinEnd" element={<PinEntry />} />
          <Route path="/success" element={<Success />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/summary" element={<Summary />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
