import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import './Brands.css'; // Optional, for specific page styles

const Brands = () => {
    const location = useLocation();
    const { rating, contactInfo } = location.state || {};
    const [selectedBrands, setSelectedBrands] = useState([]);
    const navigate = useNavigate();

    const brands = [
        'Camel', 'Lucky Strike', 'Winston',
        'Gauloises', 'Marlboro', 'Davidoff',
        'Dunhill', 'B&H', 'Philip Morris',
        'Fortuna', 'Other'
    ];

    useEffect(() => {
        // Fade in animation on page load
        gsap.fromTo(".page-container", { opacity: 0 }, { opacity: 1, duration: 1 });
    }, []);

    const handleBrandChange = (brand) => {
        setSelectedBrands((prevSelectedBrands) =>
            prevSelectedBrands.includes(brand) ?
            prevSelectedBrands.filter((b) => b !== brand) : [...prevSelectedBrands, brand]
        );
    };

    const handleBrandSubmit = () => {
        // Fade out animation and then navigate
        gsap.to(".page-container", {
            opacity: 0,
            duration: 1,
            onComplete: () => {
                navigate('/summary', { state: { rating, selectedBrands, contactInfo } });
            }
        });
    };

    const handleSkip = () => {
        // Fade out animation and then navigate
        gsap.to(".page-container", {
            opacity: 0,
            duration: 1,
            onComplete: () => {
                navigate('/'); // Redirect to the home page
            }
        });
    };

    return ( <
        div className = "brands page-container" >
        <
        div className = 'page-header' >
        <
        h1 className = 'page-title-small' > Select Brands You Smoke < /h1> < /
        div > <
        div className = 'page-content' >
        <
        div className = "brand-list" > {
            brands.map((brand) => ( <
                div key = { brand }
                className = "brand-item" >
                <
                input type = "checkbox"
                id = { brand }
                value = { brand }
                checked = { selectedBrands.includes(brand) }
                onChange = {
                    () => handleBrandChange(brand)
                }
                /> <
                label htmlFor = { brand } > { brand } < /label> < /
                div >
            ))
        } <
        /div> < /
        div > <
        div className = 'page-footer' >
        <
        button className = "page-button"
        onClick = { handleBrandSubmit } > Submit < /button> <
        button className = "page-button button-skip"
        onClick = { handleSkip } > Skip < /button> < /
        div > <
        /div>
    );
};

export default Brands;