import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { initializeDatabase, insertResult, fetchResultByWinnerId } from '../services/database';
import './Summary.css'; // Optional, for specific page styles

const Summary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { rating, selectedBrands, contactInfo } = location.state || {};
    const [debugMode, setDebugMode] = useState(false);
    const [alwaysWin, setAlwaysWin] = useState(false);
    const [hasSaved, setHasSaved] = useState(false); // Guard to prevent multiple saves

    useEffect(() => {
        const loadConfig = async() => {
            const savedDebugMode = await localforage.getItem('debugMode');
            const savedAlwaysWin = await localforage.getItem('alwaysWin');

            if (savedDebugMode !== null) setDebugMode(savedDebugMode);
            if (savedAlwaysWin !== null) setAlwaysWin(savedAlwaysWin);
        };

        loadConfig();
    }, []);

    useEffect(() => {
        const saveSummary = async() => {
            console.log('Initializing database...');
            await initializeDatabase();
            console.log('Database initialized.');

            let winnerId = contactInfo ? contactInfo.winner_id : 0;
            if (winnerId === 9001) {
                winnerId = 0;
            }
            if (winnerId) {
                const existingResult = await fetchResultByWinnerId(winnerId);
                if (existingResult) {
                    console.log(`Feedback for winner_id ${winnerId} already exists. Skipping insertion.`);
                    if (!debugMode) {
                        navigate('/');
                    }
                    return;
                }
            }

            const result = {
                winner_id: winnerId,
                rating,
                selected_brands: selectedBrands
            };
            console.log('Result to be inserted:', result);

            try {
                await insertResult(result);
                console.log('Result inserted successfully.');
                if (!debugMode) {
                    navigate('/');
                }
                setHasSaved(true); // Mark as saved
            } catch (error) {
                console.error('Error inserting result:', error);
            }
        };

        if (!hasSaved && rating && selectedBrands) { // Check if already saved
            console.log('Starting saveSummary...');
            saveSummary();
        } else {
            console.warn('Missing data: contactInfo, rating, or selectedBrands.');
        }
    }, [contactInfo, rating, selectedBrands, debugMode, navigate, hasSaved]);

    return ( <
        div className = "summary page-container" >
        <
        div className = 'page-header' >
        <
        h1 > Summary of Your Input < /h1> <
        /div> <
        div className = 'page-content' > {
            contactInfo && ( <
                div className = "contact-details" >
                <
                p > < strong > First Name: < /strong> {contactInfo.first_name}</p >
                <
                p > < strong > Last Name: < /strong> {contactInfo.last_name}</p >
                <
                p > < strong > Email: < /strong> {contactInfo.email}</p >
                <
                p > < strong > Selected Option: < /strong> {contactInfo.option}</p >
                <
                /div>
            )
        } <
        p > < strong > Rating: < /strong> {rating} stars</p >
        <
        p > < strong > Selected Brands: < /strong></p >
        <
        ul > {
            selectedBrands.map((brand) => ( <
                li key = { brand } > { brand } < /li>
            ))
        } <
        /ul> <
        /div> <
        div className = 'page-footer' >
        <
        button className = "page-button"
        onClick = {
            () => navigate('/') } > Go Back Home < /button> <
        /div> <
        /div>
    );
};

export default Summary;